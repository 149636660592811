export declare type MetaAttrs = Record<string, any>;

export const useMeta = () => {
  const runtimeConfig = useRuntimeConfig();
  const { localeProperties, locales, locale } = useI18n();
  const metaTag = (info: {
    title: string | undefined;
    description?: string;
    type?: string;
    image?: string;
    url: string;
  }): Array<MetaAttrs> => {
    if (!info.type) {
      info.type = "website";
    }

    var ogs: Array<MetaAttrs> = [];
    var twitters: Array<MetaAttrs> = [];
    const keyInOg = ["url", "title", "type", "description", "image"];
    const keyTwitter = ["url", "title", "description", "image"];
    Object.keys(info).forEach((item) => {
      if (keyInOg.includes(item)) {
        ogs.push({
          property: "og:" + item,
          //@ts-ignore
          content: info[item],
        });
      }
      if (keyTwitter.includes(item)) {
        twitters.push({
          name: "twitter:" + item,
          //@ts-ignore
          content: info[item],
        });
      }
    });
    return [...ogs, ...twitters];
  };

  const currentURL = (): string => {
    const route = useRoute();
    return runtimeConfig.public.app_url + route.path;
  };
  const inLanguages = (current: boolean = false) => {
    if (current) {
      return localeProperties.value.iso;
    } else {
      var ars: string[] = [];
      (locales.value as Array<{ iso: string }>).forEach((locale) => {
        ars.push(locale.iso);
      });
      return ars;
    }
  };
  const currentHost = (filter =true) => {
    var ex = "";
    if (filter && locale.value != "en") {
      ex = "/" + locale.value;
    }
    return runtimeConfig.public.app_url + ex;
  };
  const assetImage = (path: string) => {
    return runtimeConfig.public.app_url + path;
  };
  return {
    metaTag,
    currentURL,
    currentHost,
    inLanguages,
    assetImage,
  };
};
